import {CSSTransition} from "react-transition-group";
import React from "react";

import './fade.scss';

type Props = React.PropsWithChildren<{in: boolean, className?: string}>
export default function FadeIn(props: Props) {
    return (
        <CSSTransition in={props.in} timeout={200} classNames="fade" appear={true} className={"fade-wrapper " + props.className}>
            {props.children}
        </CSSTransition>
    )
}
