import React from 'react';
import './App.scss';
import {
    BrowserRouter as Router,
    Switch,
    Route
} from "react-router-dom";
import Home from "./pages/Home";
import Hana from "./pages/hana/Hana";
import {CssBaseline} from "@material-ui/core";

function App() {
    return (
        <Router>
            <div>
                <CssBaseline />
                <Switch>
                    <Route path="/hana">
                        <Hana/>
                    </Route>
                    <Route path="/">
                        <Home/>
                    </Route>
                </Switch>
            </div>
        </Router>
    );
}

export default App;
