import {createMuiTheme} from "@material-ui/core";

const theme = createMuiTheme({
    palette: {
        primary: {
            main: '#b32e01',
            light: '#ec6031',
            dark: '#7c0000'
        },
        secondary: {
            main: '#90a7b7',
            light: '#c1d8e9',
            dark: '#627887'
        },
    },
    spacing: 6,
    props: {
        MuiButtonBase: {
            disableRipple: true
        },
    },
});

export default theme;
