import React, {createRef, useEffect} from 'react';

type Props = {
    aspect: number
    drawCommand: (props: any) => void,
    drawCommandArguments: any
}
export default function ReglCanvas(props: Props) {
    const canvasRef = createRef<HTMLCanvasElement>();

    useEffect(() => {
        const canvas = canvasRef.current!;
        if (canvas && canvas.parentElement) {
            const height = canvas.parentElement.offsetHeight;
            const width = height * (props.aspect);
            props.drawCommand({canvas, width, height, ...props.drawCommandArguments});
        }
    }, []);


    return (
        <canvas ref={canvasRef}/>
    )
}
