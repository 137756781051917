import comingSoon from "../assets/coming-soon-min.png";
import SocialIcon from "../components/SocialIcon";
import {Link} from "react-router-dom";
import React from "react";

import "./Home.scss"
import FadeImage from "../components/FadeImage";
import Div100vh from "react-div-100vh";

function Home() {
    return (
        <Div100vh className="Home">
            <header className="Home-header">
                <h1>maya_ndljk</h1>
                <FadeImage src={comingSoon} alt="logo" className="Home-logo" />
            </header>

            <div className="Home-social-links">
                <SocialIcon link="https://instagram.com/maya_ndljk" icon="instagram"/>
                <SocialIcon link="https://twitter.com/maya_ndljk" icon="twitter"/>
                <SocialIcon link="https://github.com/mayacoda" icon="github"/>
            </div>

            <h4>some art:</h4>
            <p>(<Link to="/hana">HANA</Link>)</p>
        </Div100vh>
    );
}

export default Home;
