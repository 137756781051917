import React from "react";
import Icons from '../assets/icons/icons.svg';
import styles from './SocialIcon.module.scss';

type Props = React.PropsWithChildren<{ link: string, icon: string, className?: string }>;

function SocialIcon({link, icon, children, className = ''}: Props) {
    return (
        <a href={link} className={[styles.iconLink, className].join(' ')} target="_blank" rel="noopener noreferrer">
            <svg className={styles.icon + ` icon icon-${icon}`}>
                <use xlinkHref={`${Icons}#icon-${icon}`}/>
            </svg>
            {children && <span className={styles.linkText}>{children}</span>}
        </a>
    );
}

export default SocialIcon;
