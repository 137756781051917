import FadeIn from "./FadeIn";
import React, {useState} from "react";

type Props = { src: string, alt?: string, className: string } & any;
export default function FadeImage({src, alt, ...props}: Props) {
    const [loadedImage, setLoadedImage] = useState(false);

    function onImageLoad() {
        setLoadedImage(true)
    }

    return (
        <FadeIn in={loadedImage} className={props.className}>
            <img src={src} alt={alt} {...props} onLoad={onImageLoad} onClick={onImageLoad}/>
        </FadeIn>
    )
}
