import React, {useEffect, useMemo, useState} from 'react';
import {Link} from 'react-router-dom';
import {initCanvas, initColors} from 'hana-glitter';
import ReglCanvas from '../../components/ReglCanvas';
import HanaImage from './assets/hana.png';
import Loading from './assets/loading-min-16.png';
import Mask from './assets/mask.png'
import {fetchImage} from "../../utils/file-utils";
import styles from './Hana.module.scss';
import FadeImage from '../../components/FadeImage';
import Div100vh from 'react-div-100vh'
import {Fab, Paper, ThemeProvider, Tooltip} from "@material-ui/core";
import SocialIcon from "../../components/SocialIcon";
import theme from "../../material-ui-theme";

function fetchImages() {
    return Promise.all([fetchImage(Mask), fetchImage(HanaImage)]).then(([mask, image]) => ({
        mask,
        image
    }))
}

const promise = fetchImages();

export default function Hana() {
    const [mask, setMask] = useState<HTMLImageElement | null>(null);
    const [image, setImage] = useState<HTMLImageElement | null>(null);
    const [palette, setPalette] = useState<string[]>([]);
    const [disco, setDisco] = useState<string>('✸');
    const [showTooltip, setShowTooltip] = useState(true);
    const hasLoaded = useMemo(() => mask !== null && image !== null, [mask, image]);

    let stars = ['✷', '✶', '✵'];

    useEffect(() => {
        promise.then(data => {
            setMask(data.mask);
            setImage(data.image);
        })
    }, []);

    function changeColors() {
        setShowTooltip(false);
        setPalette(initColors());
    }

    useEffect(() => {
        const interval = setInterval(() => {
            setDisco(disco => {
                stars.push(disco);
                return stars.shift() || ''
            });
        }, 1000);

        return () => clearInterval(interval)
    }, [stars]);

    return (
        <Div100vh className={styles.Hana}>
            <nav className={styles.HanaNavigation}>
                <Link to="/">&larr; Back</Link>
            </nav>

            <div className={styles.HanaContainer}>
                <Paper className={styles.HanaControls}>
                    <ThemeProvider theme={theme}>
                        {hasLoaded && <Tooltip title="Make some magic" arrow={true} open={showTooltip}>
                            <Fab onClick={changeColors} variant="round" color="primary"
                                 style={{fontSize: '30px'}}>{disco}</Fab>
                        </Tooltip>}
                        <div className={styles.HanaColorsContainer}>
                            {
                                palette.map((c, index) => <div className={styles.HanaColor}
                                                               key={c + index}
                                                               style={{backgroundColor: c}}/>)
                            }
                        </div>
                        <div className={styles.HanaGitHubLink}>
                            <SocialIcon icon="github"
                                        link="https://github.com/mayacoda/hana-glitter">view on
                                github</SocialIcon>
                        </div>
                    </ThemeProvider>
                </Paper>
                <div className={styles.HanaCanvasContainer}>
                    <FadeImage
                        className={styles.HanaLoader + (hasLoaded ? ' ' + styles.HanaFadeOut : '')}
                        src={Loading} alt=""/>
                    {(mask !== null && image !== null) &&
                    <ReglCanvas drawCommand={initCanvas}
                                drawCommandArguments={{mask, image}}
                                aspect={0.7070707071}/>
                    }
                </div>
            </div>
        </Div100vh>
    )
}
